import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { BrowserRouter } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import globalTranslations from './global.translation.json';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import EventsApp from './EventsApp';
import { StateProvider } from './store.js';
import * as serviceWorker from './serviceWorker';

const widgetRoot = document.getElementById('events-widget');
const eventId = widgetRoot ? widgetRoot.getAttribute('data-eventId') : null;

if (widgetRoot && eventId) {
  const useFilters = widgetRoot.getAttribute('data-filters') === "false" ? false : true;
  const lang = widgetRoot.getAttribute('data-lang') || 'no';
  const token = widgetRoot.getAttribute('data-token');
  const config = { eventId, lang, token, useFilters };

  ReactDOM.render((
    <BrowserRouter>
      <StateProvider>
        <LocalizeProvider
            initialize={{
              languages: [
                { name: 'Norwegian', code: 'no' },
                { name: 'English', code: 'en' },
              ],
              translation: globalTranslations,
              options: {
                renderInnerHtml: true,
                defaultLanguage: lang,
                renderToStaticMarkup: ReactDOMServer.renderToStaticMarkup,
              },
            }}
          >
          <EventsApp config={config} />
        </LocalizeProvider>
      </StateProvider>
    </BrowserRouter>
  ), widgetRoot);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
