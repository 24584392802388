import React from 'react';
import PropTypes from 'prop-types';
import { DoubleBounce } from 'better-react-spinkit';
import s from './Spinner.module.css';

function Spinner(props) {
    return (
      <div
        className={s.spinnerWrapper}
        style={{
          marginTop: props.marginTop,
          marginBottom: props.marginBottom,
        }}
      >
        <div className={s.spinner}>
          <DoubleBounce size={35} color="#044594" />
        </div>
      </div>
    );
}

Spinner.propTypes = {
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
};

Spinner.defaultProps = {
  marginTop: 90,
  marginBottom: 0,
};

export default Spinner;
