import React, { useState, useContext } from 'react';
import Button from 'components/Button';
import PropTypes from 'prop-types';
import { Translate } from 'react-localize-redux';
import { store } from 'store.js';
import { ReactComponent as ActiveIcon } from './star-active-icon.svg';
import { ReactComponent as InactiveIcon } from './star-inactive-icon.svg';
import s from './FavoriteButton.module.css';

const FavoriteButton = props => {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { favorites } = globalState.state;
  const { token, eventId } = globalState.state.config;
  const exhibitorMode = props.exhibitor;
  const [favorite, setFavorite] = useState(favorites && favorites.includes(props.id));
  const icon = favorite ? <ActiveIcon className={s.buttonIcon} /> : <InactiveIcon className={s.buttonIcon} />;

  const toggleFavorite = async event => {
    event.preventDefault();
    event.stopPropagation();

    setFavorite(!favorite);
    let requestData, res;
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${token}`);
    const fetchAttr = exhibitorMode ? 'exhibitor_ids' : 'program_block_ids';

    if (favorite) {
      requestData = { headers, method: 'DELETE' };
      const fetchUrl = exhibitorMode ? `${process.env.REACT_APP_API}/api/event/${eventId}/user/exhibitor_favorite/${props.id}` : `${process.env.REACT_APP_API}/api/user/favorite/${props.id}`;
      res = await fetch(fetchUrl, requestData);
    } else {
      requestData = { headers, method: 'POST' };
      const fetchUrl = exhibitorMode ? `${process.env.REACT_APP_API}/api/event/${eventId}/user/exhibitor_favorite/${props.id}` : `${process.env.REACT_APP_API}/api/event/${eventId}/user/favorite/${props.id}`;
      res = await fetch(fetchUrl, requestData);
    }

    res
      .json()
      .then(res => {
        dispatch({type: 'GET_FAVORITES', payload: res[fetchAttr]});
      })
  }

  return (
    token ? (
      <Button
        handleClick={e => toggleFavorite(e)}
        label={<Translate id="favorite" />}
        marginTop="0"
        styling="neutral"
        size="xsmall"
        iconComponent={icon}
      />
    ) : null
  )
}

FavoriteButton.propTypes = {
  id: PropTypes.number.isRequired,
}

export default FavoriteButton;
