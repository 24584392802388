// import '../../Forms/InputTypeText/_index.scss';
import React, { useEffect, forwardRef, useState } from 'react';
import PhoneInput, {formatPhoneNumberIntl, parsePhoneNumber} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import s from './MeetingRequest.module.css';
/**
 * Custom phone input
 */
export const InputTypePhone = forwardRef((props, ref) => {
  const {
    className,
    name, 
    label, 
    onChange,
    value,
    disabled, 
    readOnly,
  } = props;

 
//   useEffect(() => {
//     const phoneNumber = formatPhoneNumberIntl(tempValue);
//     const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
//     console.log(parsedPhoneNumber);
//     if (tempValue) {
// //       onChange({
// //         currentTarget: {
// //           name: name,
// //           value: phoneNumber,
// //         }
// //       });
//       if (parsedPhoneNumber && parsedPhoneNumber.country) {

//         console.log('parsedPhoneNumber', parsedPhoneNumber);
// //         onChange({
// //           currentTarget: {
// //             name: 'country_code',
// //             value: parsedPhoneNumber.country,
// //           }
// //         });
//       }
//     }
//   }, [tempValue]);

  return (
    <div className={s.phoneInputWrap}> 
      <input name={name} type="hidden"  {...ref} />
      <PhoneInput
        value={value}
        label={label}
        disabled={disabled}
        readOnly={readOnly}
        international
        onChange={onChange}
        countryCallingCodeEditable={false}
        className={`inputTypePhone form-control ${className? className: ''}`}
        defaultCountry="NO"
      />
    </div>
  );
});