export const hall = standNumber => {
  const standLetters = standNumber && standNumber.length > 0 ? standNumber.match(/[a-zA-Z]+/g) : false;
  if (!standLetters) return '';

  const standLettersExist = standLetters !== null && standLetters.length > 0;
  const hallInfo = standLettersExist ? `Hall ${standLetters[0]}` : '';

  return standLettersExist && standLetters[0].length > 2 ? standLetters[0] : hallInfo;
}

export const getStartTime = date => {
  return date && date.starttime && date.starttime.length > 0 ? date.starttime.substring(0, 10) : null;
};

/**
 * Sort array of objects by 'starttime' param
 * @param {object} a - event item A
 * @param {object} b - event item B
 */
const byStartDate = (a, b) => {
  if (a && a.starttime && b && b.starttime) {
    const dateA = new Date(a.starttime.replace(/-/g, "/"));
    const dateB = new Date(b.starttime.replace(/-/g, "/"));

    return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
  }

  return -1;
}

/**
 * Add repeats to events list
 * @param {object} events - events list 
 * @param {boolean} sortList - whether to sort the list or not
 */
export const withRepeats = (events, sortList) => {
  const result = [];

  events && Array.isArray(events) && events.map(event => {
    const { repeats } = event;

    result.push(event);

    if (repeats && Array.isArray(repeats)) {
      repeats.map(repeat => {
        const repeatItem = {...event};

        repeatItem.starttime = repeat.starttime;
        repeatItem.endtime = repeat.endtime;
        repeatItem.id = repeat.program_block_id;
        repeatItem.programId = repeat.id;

        result.push(repeatItem);
      });
    }
  });

  return sortList ? result.sort(byStartDate) : result;
};
