import React from 'react';
import { Row, Col } from 'reactstrap';
import { Translate } from 'react-localize-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from './back-icon.svg';
import s from './BackLink.module.css';

function BackLink(props) {
    const history = useHistory();

    return (
      <Row className="mb-4 p-0 btn" onClick={() => props.customFn ? props.customFn() : history.goBack()}>
        <Col className="col-12">
            <BackIcon className={s.backIcon} />
            <b className={s.title}><Translate id="backLink" /></b>
        </Col>
      </Row>
    );
}

export default BackLink;
