import React, { useEffect, useContext, useState } from "react";
import EventsHome from "routes/EventsHome";
import s from "./App.module.css";
import Spinner from "components/Spinner";
import "moment/locale/nb";
import { store } from "store.js";

function EventsApp(props) {
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const { token, eventId, lang } = props.config;
  const showLang = lang === "no" ? "no" : "en";

  const [status, setStatus] = useState({
    loading: true,
    errors: false,
  });

  const fetchFavorites = async () => {
    const headers = new Headers();
    headers.append("Authorization", `Bearer ${token}`);
    const requestData = { headers };
    const res = await fetch(
      `${process.env.REACT_APP_API}/api/event/${eventId}/user/exhibitor_favorite`,
      requestData
    );
    res
      .json()
      .then((res) => {
        setStatus({ loading: false });
        dispatch({ type: "GET_FAVORITES", payload: res.exhibitor_ids });
      })
      .catch((err) =>
        dispatch({ type: "SET_CONFIG", payload: { token: null } })
      );
  };

  useEffect(() => {
    dispatch({ type: "SET_CONFIG", payload: props.config });
    dispatch({ type: "UPDATE_LANG", payload: showLang });
    if (token) {
      fetchFavorites();
    }
    setStatus({ loading: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  return (
    <div className={`${s.App} program_widget_venyou_sd0`}>
      {status.loading && <Spinner />}
      {!status.loading && <EventsHome />}
    </div>
  );
}

export default EventsApp;
