import React, { useContext, useState } from "react";
import s from "./Filter.module.css";
import { Row, Col } from "reactstrap";
import Select, { components } from "react-select";
import { store } from "store.js";
import { Translate } from "react-localize-redux";
import ChangeLanguage from "components/ChangeLanguage";
import MobileFilter from "components/MobileFilter";
import useWindowDimensions from "components/WindowDimensions";
import Button from "components/Button";
import { ReactComponent as FilterIcon } from "./filter-icon.svg";
import { ReactComponent as ActiveIcon } from "./star-active-icon.svg";
import { ReactComponent as InactiveIcon } from "./star-inactive-icon.svg";
import Search from "components/Search";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/no.json"));

const ValueContainer = ({ children, getValue, ...props }) => {
  const length = getValue().length;
  const fallbackMsg =
    length > 0 ? getValue()[0].label : props.selectProps.placeholder;

  return (
    <components.ValueContainer {...props}>
      {!props.selectProps.inputValue &&
        (length > 1 ? (
          <React.Fragment>
            {getValue()[0].label}{" "}
            <span className={s.oneMore}>
              +{length - 1} <Translate id="more" />
            </span>
          </React.Fragment>
        ) : (
          fallbackMsg
        ))}
      {React.Children.map(children, (child) => {
        return child.type === components.Input ? child : null;
      })}
    </components.ValueContainer>
  );
};

const customStyles = {
  control: (base) => ({
    ...base,
    height: 38,
    minHeight: 38,
    boxShadow: "none",
  }),
};

function Filter(props) {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { token } = state.config;
  const { filter, showFilter, lang } = state;

  const [mobileFilter, showMobileFilter] = useState(false);
  const showFavoriteIcon = filter.favorites ? (
    <ActiveIcon className={s.buttonIcon} />
  ) : (
    <InactiveIcon className={s.buttonIcon} />
  );
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  const toggleShowFavorite = () => {
    dispatch({ type: "FILTER", payload: { favorites: !filter.favorites } });
  };

  const handleFilter = (input, cat) => {
    dispatch({ type: "FILTER", payload: { [cat]: input } });
  };

  const filtersDom = props.filtersDom.map((fd) => ({
    ...fd,
    placeholder: lang === "no" ? fd.placeholder_no : fd.placeholder,
    options: showFilter[fd.id],
  }));

  return (
    <Row className="no-gutters">
      {token ? (
        <Col className="col-auto pr-3">
          <Button
            handleClick={() => toggleShowFavorite()}
            label={<Translate id="showMyFavorite" />}
            marginTop="0"
            disabled={!props.favoritesAvailable ? true : false}
            size="small"
            styling="neutral"
            iconComponent={showFavoriteIcon}
          />
        </Col>
      ) : null}
      {isDesktop ? (
        <React.Fragment>
          <Col className="col-auto">
            <Search />
          </Col>
          {props.useFilters && filtersDom.map((el, i) => {
            let fieldValue;
            switch (el.id) {
              case "categories":
                if (el.options && lang === "en") {
                  el.options.forEach((o, i) => {
                    o.label = o.label_en;
                  });
                }
                if (el.options && lang === "no") {
                  el.options.forEach((o, i) => {
                    o.label = o.value;
                  });
                }
                fieldValue =
                  el.options &&
                  el.options.length > 0 &&
                  filter.categories &&
                  filter.categories.length === 1
                    ? el.options.filter(
                        (a) => a.label === filter.categories[0].label
                      )
                    : undefined;

                break;
              case "brands":
                fieldValue =
                  el.options &&
                  el.options.length > 0 &&
                  filter.brands &&
                  filter.brands.length === 1
                    ? el.options.filter(
                        (a) => a.label === filter.brands[0].label
                      )
                    : undefined;
                break;
              case "allCountries":
                if (el.options && lang === "en") {
                  el.options.forEach((o, i) => {
                    o.label = o.label_en;
                  });
                }
                
                fieldValue =
                  el.options &&
                  el.options.length > 0 &&
                  filter.allCountries &&
                  filter.allCountries.length === 1
                    ? el.options.filter(
                        (a) => a.id === filter.allCountries[0].id
                      )
                    : undefined;
              default:
                break;
            }

            return (
              <Col key={el.id} className="col-auto pl-2" style={{ zIndex: 2 }}>
                <Select
                  value={fieldValue}
                  isMulti
                  isSearchable={false}
                  styles={customStyles}
                  name={el.id}
                  options={el.options}
                  className="widget-basic-multi-select"
                  classNamePrefix="select"
                  placeholder={el.placeholder}
                  hideSelectedOptions={false}
                  onChange={(e) => handleFilter(e, el.id)}
                  components={{ MultiValue: ValueContainer }}
                />
              </Col>
            );
          })}
          <Col className="col-auto pl-2" style={{ zIndex: 3 }}>
            <ChangeLanguage />
          </Col>
        </React.Fragment>
      ) : (
        <Col className="col-auto pr-3">
          <Button
            handleClick={() => showMobileFilter(!mobileFilter)}
            label={<Translate id="filter" />}
            marginTop="0"
            styling="neutral"
            size="small"
            iconComponent={<FilterIcon className={s.buttonIcon} />}
          />
          {mobileFilter && (
            <MobileFilter
              handleChange={handleFilter}
              toggleMobileFilter={showMobileFilter}
              activeFilters={filter}
              filters={filtersDom}
              useFilters={props.useFilters}
            />
          )}
        </Col>
      )}
    </Row>
  );
}

export default Filter;
