import React from 'react';
import { Row, Col } from 'reactstrap';
import s from './MobileFilterOption.module.css';

function MobileFilterOption(props) {
  return (
    <Row className={`${s.root} align-items-center`} onClick={props.click}>
      <Col className="col-9">{props.label}</Col>
      <Col className="col-3 text-right">{props.icon}</Col>
    </Row>
  )
}

export default MobileFilterOption;
