import React, { useState, useEffect, useContext } from "react";
import s from "./EventsHome.module.css";
import Filter from "components/Filter";
import EventItem from "components/EventItem";
import { hall } from "helpers";
import Spinner from "components/Spinner";
import { store } from "store.js";
import countries from "i18n-iso-countries";
import {withRepeats} from '../../helpers';
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/no.json"));

function addUnique(array) {
  const result = [];
  const map = new Map();
  for (const item of array) {
    if (item && !map.has(item.id)) {
      map.set(item.id, true);
      result.push(item);
    }
  }
  return result.sort((a, b) => a.label.localeCompare(b.label));
}

function filterEvents(events, filter, favorites) {
  events =
    !filter.favorites || !favorites || favorites.length === 0
      ? events
      : events.filter((a) => favorites.includes(a.id));
  events =
    filter.categories && filter.categories.length > 0
      ? events.filter((a) =>
          a.product_categories.some((b) =>
            filter.categories.some((c) => c.id === b.id)
          )
        )
      : events;
  events =
    filter.venues && filter.venues.length > 0
      ? events.filter((a) =>
          filter.venues.some((b) => b.id === hall(a.standnumber))
        )
      : events;
  events =
    filter.brands && filter.brands.length > 0
      ? events.filter((a) =>
          a.brands.some((b) => filter.brands.some((c) => c.id === b.id))
        )
      : events;
  events =
    filter.allCountries && filter.allCountries.length > 0
      ? events.filter((a) =>
          filter.allCountries.some((b) => b.id === a.countryCode)
        )
      : events;
  events =
    filter.search && filter.search.length > 0
      ? events.filter((a) => {
        return a.standname && a.standname.toLowerCase().includes(filter.search.toLowerCase()) ||
        a.company_name && a.company_name.toLowerCase().includes(filter.search.toLowerCase()) ||
        a.parent_company_name && a.parent_company_name.toLowerCase().includes(filter.search.toLowerCase()) ||
        a.parent_stand_name && a.parent_stand_name.toLowerCase().includes(filter.search.toLowerCase())
      })
        
      : events;

  return events;
}

function EventsHome() {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { eventId, category, useFilters } = state.config;
  const { favorites, filter } = state;

  const [status, setStatus] = useState({
    loading: true,
    errors: false,
  });
  const showEvents =
    !status.loading &&
    globalState.state.events &&
    globalState.state.events.length > 0;

  async function fetchData() {
    const res = await fetch(
      `${process.env.REACT_APP_API}/v1/public/exhibitors?embed=stands,brands,product_categories&event_id=${eventId}`
    );
    res
      .json()
      .then((data) => {
        let venues, uniqueBrands, allCountries;
        const stands = [];

        const findParent = function(data, parent_id) {
          let parent = null;
          data.forEach((company, companyIndex) => {
            company.stands.forEach((stand) => {
              if (stand.id === parent_id) {
                parent = company;
              }
            });
          });
          return parent;
        };
      
        data.forEach((company, companyIndex) => {
          company.stands.forEach((stand) => {
            stand.url = company.company_url;
            stand.company_name = company.company_name;
            stand.countryCode = company.company_countrycode;
            stand.parent_company_name = '';
            stand.parent_stand_name = '';
            stand.event_name = company.event_name;
            
            if (stand.parent_id !== null) {
              var parent = findParent(data, stand.parent_id);

              if (parent !== null) {
                stand.parent_company_name = parent.company_name;
                stand.parent_stand_name = parent.stands.filter(s => {
                  return s.id === stand.parent_id
                })[0].standname;
              }
            }

            stands.push(stand);

          });
        });

        const catFiltered = category
          ? stands.filter((stand) =>
              stand.product_categories.some(
                (category) => category.id === parseInt(category)
              )
            )
          : stands;
        setStatus({ loading: false });
        dispatch({ type: "FETCH_EVENTS", payload: catFiltered });

        const categoriesWithDuplicates = stands.map((stand) => {
          if (stand && stand.product_categories && Array.isArray(stand.product_categories)) {
            return stand.product_categories.map((category) => {
              if (category && category.name) {
                return {
                  id: category.id,
                  label: category.name,
                  value: category.name,
                  label_en: category.en.name,
                };
              }
            });
          }
        }).flat();

        const uniqueCategories = addUnique(categoriesWithDuplicates);

        try {
          const brandsWithDuplicates = stands.map((stand) => {
            if (stand && stand.brands && Array.isArray(stand.brands)) {
              return stand.brands.map((b) => {
                if (b && b.name) {
                  return {
                    id: b.id,
                    label: b.name,
                    value: b.name,
                  };
                }
              })
            }
          }).flat();

          uniqueBrands = addUnique(brandsWithDuplicates);
        } catch (e) {
          console.log("error", e);
        }
        try {
          venues = addUnique(
            stands
              .filter((a) => hall(a.standnumber).length > 0)
              .map((a) => ({
                id: hall(a.standnumber),
                label: hall(a.standnumber),
                value: hall(a.standnumber),
              }))
          );
        } catch (e) {
          console.log("error", e);
        }
        try {
          allCountries = addUnique(
            stands
              .filter((a) => a.countryCode !== null)
              .map((a) => ({
                id: a.countryCode,
                label: countries.getName(a.countryCode, "no", {select: "official"}),
                label_en: countries.getName(a.countryCode, "en", {select: "official"}),
                value: a.countryCode
              })).sort((a, b) => a.id.localeCompare(b.id))

          );
        } catch (e) {
          console.log("error", e);
        }

        const setFilters = {
          categories: uniqueCategories,
          brands: uniqueBrands,
          venues,
          allCountries
        };

        dispatch({ type: "SET_FILTER", payload: setFilters });
      })
      .catch((err) => setStatus({ loading: false, errors: err }));
  }

  useEffect(() => {
    if (
      !globalState.state.events ||
      (globalState.state.events && globalState.state.events.length === 0)
    ) {
      fetchData();
    } else {
      setStatus({ loading: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const events = withRepeats(filterEvents(globalState.state.events, filter, favorites), false/* true */);
  const filtersDom = [
    {
      id: "categories",
      placeholder: "Categories",
      placeholder_no: "Kategorier",
    },
    { id: "venues", placeholder: "Location", placeholder_no: "Lokasjon" },
    { id: "brands", placeholder: "Brands", placeholder_no: "Varemerker" },
    { id: "allCountries", placeholder: "Countries", placeholder_no: "Land" }
  ];

  const changeCat = (cat) => {
    const newCat = [{ id: cat.id, label: cat.name, value: cat.name }];
    dispatch({ type: "FILTER", payload: { categories: newCat } });
  };

  const changeBrand = (brand) => {
    const newBrand = [{ id: brand.id, label: brand.name, value: brand.name }];
    dispatch({ type: "FILTER", payload: { brands: newBrand } });
  };

  return (
    <div className={s.root}>
      {showEvents && (
        <React.Fragment>
          <div>
            <Filter
              filtersDom={filtersDom}
              favoritesAvailable={favorites && favorites.length > 0}
              useFilters={useFilters}
            />
          </div>
          <div>
            {events &&
              events.map((e) => (
                <EventItem
                  filter={filter}
                  changeCat={changeCat}
                  changeBrand={changeBrand}
                  key={e.id}
                  data={e}
                />
              ))}
          </div>
        </React.Fragment>
      )}
      {!status.loading && !showEvents && (
        <h5 className="text-center mt-5 pt-3">
          Exhibitor list is not ready yet
        </h5>
      )}
      {status.loading && <Spinner />}
    </div>
  );
}

export default EventsHome;
