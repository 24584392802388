import React, { useState, useContext } from 'react';
import { store } from 'store.js';
import { Translate } from 'react-localize-redux';
import Button from 'components/Button';
import { Row, Col, Modal, ModalBody, Input } from "reactstrap";
import {DatePicker} from 'reactstrap-date-picker'
import { useForm } from "react-hook-form";
import s from './MeetingRequest.module.css';
import {InputTypePhone} from './InputPhoneNumber';
import Spinner from 'components/Spinner';
import { isPossiblePhoneNumber } from 'react-phone-number-input';


export default function MeetingRequest({itemData}) {
    
    const globalState = useContext(store);
    const { token } = globalState.state.config;
    const [isExpanded, setIsExpanded] = useState(false);
    const [loading, setLoading] =  useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [errMsg, setErrorMsg] = useState('');

    const {
        event_id,  
        event_name,  
        id, 
        standnumber, 
        allows_meeting_requests, 
        company_name, 
        meeting_request_emails
    } = itemData;


    const onExpandClick =  () => {
        setIsExpanded(!isExpanded);
    };
    
    const openModal = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsExpanded(true);
        setShowSuccessMessage(false);
        setLoading(false);
        setErrorMsg('');
    };

    const { register, handleSubmit, watch, setValue, clearErrors, formState: { errors } } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);

        let requestData = {
            'name': data.name,
            'email': data.email,
            'mobile_number': data.mobile_number,
            'suggested_datetime': `${data.suggested_date} ${data.suggested_time}`,
            'suggested_location': data.suggested_location,
            'information': data.information?  data.information: null
        };

        let requestUrl = `${process.env.REACT_APP_API}/api/mystand/event/${event_id}/stand/${id}/meeting_request`;
        let res =  await fetch(`${requestUrl}`, {
            headers: {
                "Authorization":  `Bearer ${token}`,
                "Content-Type": "application/json; charset=utf-8",
                "Accept": "application/json"
            }, 
            method: 'POST',
            body: JSON.stringify(requestData)
         })
        .then((response) => response.json())
        .then((data) => {
            if(data && data.success) {
                setShowSuccessMessage(true);
            }

            if(data && data.error) {
                if(typeof data.error == "string" )  {
                    setErrorMsg(data.error);
                }
            }


           setLoading(false);
        });
    };
    const watchData = watch();

    return allows_meeting_requests && meeting_request_emails?
     <>
        <Button
            handleClick={e => openModal(e)}
            label={<Translate id="meetingRequest.meetingRequestLabel" />}
            marginTop="0"
            styling="successOutline"
            size="xsmall"
        />
        
       {(isExpanded  && token)? (
            <Modal isOpen={isExpanded} toggle={onExpandClick} centered={true}>
                <ModalBody>
                    {
                        showSuccessMessage? 
                        <h2 className={s.formSuccessMessageTitle}>
                            <Translate 
                                id="meetingRequest.meetingRequestSuccessMessage"
                            />
                        </h2>

                        : <div className={s.formWrapper}>
                        
                            {
                                loading?
                                <div className={s.spinerWrapper}>
                                    <Spinner />
                                </div>
                                :null
                            }

                            <h2 className={s.formTitle}>
                                <Translate 
                                    id="meetingRequest.meetingRequestLabel"
                                />
                            </h2>
                            <p>
                                <Translate 
                                        id="meetingRequest.formP1" 
                                        data={{
                                            event_name: event_name,
                                            company_name: company_name,
                                        }}
                                />
                            </p>
                            <p>
                            <Translate id="meetingRequest.formP2" />
                            </p>

                            <form onSubmit={handleSubmit(onSubmit)}>

                                <fieldset disabled={loading}>
                                    <div className={s.formRow}>
                                        <label className={s.labelField}>
                                            <Translate id="meetingRequest.formLabelName" />
                                        </label>
                                        <WrappedInput
                                            name="name"
                                            type="text"
                                            className="error"
                                            {...register("name", { required: true })} 
                                            invalid = {errors.name? true: false}
                                        />
                                    </div>
                                    
                                    <div className={s.formRow}>
                                        <label className={s.labelField}>
                                            <Translate id="meetingRequest.formLabelEmail" />
                                        </label>
                                        <WrappedInput
                                            name="email"
                                            type="text"
                                            {...register("email", { 
                                                required: true,
                                                pattern: {
                                                    value: /\S+@\S+\.\S+/,
                                                    message: "Entered value does not match email format"
                                                }
                                            })} 
                                            invalid = {errors.email? true: false}
                                        />
                                    </div>
                                    <div className={s.formRow}>
                                        <label className={s.labelField}>
                                            <Translate id="meetingRequest.formLabelTelephone" />
                                        </label>
                                        <InputTypePhone
                                            name="mobile_number"
                                            label="Mobile"
                                            value={watchData.mobile_number}
                                            onChange={(vl)=> {  
                                                setValue('mobile_number', vl);
                                                clearErrors('mobile_number');
                                            }}
                                            className = {errors.mobile_number? 'is-invalid': ''}
                                            ref= {register("mobile_number", { required: true,
                                                validate: (val)=>{ 
                                                    let msg = true;
                                                    if (!isPossiblePhoneNumber(val)) {
                                                        msg= 'Enter valid phone number'
                                                    }
                                                    return msg;
                                                }
                                            })}
                                        />
                                    </div>

                                    <div className={s.formRow}>
                                        <label className={s.labelField}>
                                            <Translate id="meetingRequest.formLabelSuggestedDateTime" />
                                        </label>
                                        <Row>
                                            <Col>
                                                <WrappedDatePicker
                                                    name="suggested_date"
                                                    id = "from-datepicker"
                                                    dateFormat = "YYYY-MM-DD"
                                                    placeholder="YYYY-MM-DD"
                                                    innerRef = {register("suggested_date", 
                                                    
                                                    {required: true,
                                                        validate: (val)=>{ 
                                                            let msg = true;
                                                            if (!(/^\d{4}-\d{2}-\d{2}$/).test(val) ) {
                                                                msg= 'Enter valid date'
                                                            }
                                                            return msg;
                                                        }
                                                    })}
                                                    showClearButton={false}
                                                    onChange= {(v,f) => {
                                                        setValue('suggested_date', f)
                                                        clearErrors('suggested_date');
                                                    }}
                                                    className = {errors.suggested_date? 'is-invalid': ''}
                                                />
                                            </Col>
                                            <Col>
                                                <WrappedInput
                                                    id="exampleTime"
                                                    placeholder="HH:mm"
                                                    name="suggested_time"
                                                    type="text"
                                                    format= 'HH:mm'
                                                    {...register("suggested_time", { 
                                                        required: true,
                                                        validate: (val)=>{ 
                                                            let msg = true;
                                                            if (!(/^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/).test(val) ) {
                                                                msg= 'Enter valid time'
                                                            }
                                                            return msg;
                                                        }
                                                    })} 
                                                    invalid = {errors.suggested_time? true: false}
                                                />
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className={s.formRow}>
                                        <label className={s.labelField}>
                                            <Translate id="meetingRequest.formLabelSuggestedLocation" />
                                        </label>
                                        <WrappedInput
                                            name="suggested_location"
                                            type="select"
                                            {...register("suggested_location")} 
                                        >
                                            <option>
                                                Lillestrøm Lobby
                                            </option>
                                            <option>
                                                Mingleområde Hall A
                                            </option>
                                            <option>
                                                På stand {standnumber}
                                            </option>
                                            <option>
                                                Annet (spesifiser nedenfor)
                                            </option>
                                        </WrappedInput>
                                    </div>
                                    
                                    <div className={s.formRow}>
                                        <label className={s.labelField}> 
                                            <Translate id="meetingRequest.formLabelInformation" />
                                        </label>

                                        <WrappedInput
                                            defaultValue = {null}
                                            name="information"
                                            type="textarea"
                                            {...register("information")} 
                                        />
                                    </div>


                                    <div className={s.sendFormBtnWrap}>  
                                        <Button
                                            disabled = {loading}
                                            label={<Translate id="meetingRequest.formLabelInformationSendBtn" />}
                                            marginTop="0"
                                            size="small"
                                            fullWidth
                                        />
                                    </div>
                                    
                                    {errMsg?
                                        <div className={s.errorMessage}>
                                            {errMsg} 
                                        </div>
                                        : null
                                    }
                                    
                                </fieldset>
                            </form>
                        </div>
                    }

                    
                
                </ModalBody>
            </Modal>
        ): 
        <Modal isOpen={isExpanded} toggle={onExpandClick} centered={true}>
            <ModalBody>
                <h2 className={s.formTitle}>
                    <Translate 
                        id="meetingRequest.meetingRequestLoginRequiredTitle"
                    />
                </h2>
                <div>
                    <Translate 
                        id="meetingRequest.meetingRequestLoginRequiredText"
                    />
                </div>
                <a 
                    className={s.followLink}
                    target= "_blank"
                    href={`https://account.novaspektrum.no/events/${event_id}/login`}>
                     https://account.novaspektrum.no/events/{event_id}/login
                </a>  
            </ModalBody>
        </Modal>
     }
     </>

    : null
}


const WrappedInput = React.forwardRef((props, ref) => (
    !props.children? <Input innerRef={ref} {...props} />: 
    <Input innerRef={ref} {...props} > {props.children} </ Input>
));



const WrappedDatePicker = React.forwardRef((props, ref) => (
   <DatePicker innerRef={ref} {...props} />
));


