import React, { useState } from 'react';
import s from './MobileFilter.module.css';
import { Translate } from 'react-localize-redux';
import { Row, Col } from 'reactstrap';
import BackLink from 'components/BackLink';
import Button from 'components/Button';
import { ReactComponent as ArrowIcon } from './arrow.svg';
import { ReactComponent as CheckOn } from './check-on.svg';
import { ReactComponent as CheckOff } from './check-off.svg';
import MobileFilterOption from './MobileFilterOption';
import Search from 'components/Search';

function MobileFilter(props) {
  const [activeCat, setActiveCat] = useState(false);
  const filteredActiveProp = Object.keys(props.activeFilters).filter(f => f !== 'favorites').reduce((o, n) => [...o, ...props.activeFilters[n]], []);
  const [mobileFilterState, setMobileFilterState] = useState(filteredActiveProp);

  const toggleOption = (cat, value) => {
    const filterOptions = mobileFilterState.some(f => f.id === value.id);

    if (filterOptions) {
      setMobileFilterState(mobileFilterState.filter(f => f.id !== value.id));
    } else {
      setMobileFilterState([
        ...mobileFilterState,
        { cat: cat, ...value},
      ]);
    }
  };

  const saveOptions = () => {
    const uniques = [...new Set(mobileFilterState.map(i => i.cat))];

    uniques.forEach(cat => props.handleChange(mobileFilterState.filter(c => c.cat === cat), cat));
    closeMobileFilter();
  }

  const resetFilters = () => {
    props.filters.forEach(cat => props.handleChange([], cat.id))
    closeMobileFilter();
  }

  const isSelected = (cat, id) => {
    const newlySelected = mobileFilterState.some(f => f.id === id);
    return newlySelected;
  };

  const showSelected = cat => {
    const activeOptions = props.activeFilters[cat.id];

    return activeOptions && activeOptions.length > 0 ?
      <span className={s.chosen}><Translate id="chosen" data={{x: activeOptions.length}} /></span>
        :
      <ArrowIcon />;
  }

  const closeMobileFilter = () => props.toggleMobileFilter(false);

  return (
    <React.Fragment>
      <div className={s.overlay} />
      <div className={s.root}>
        {activeCat ? (
          <Row>
            <Col className="col-6">
              <BackLink customFn={() => setActiveCat(null)} />
            </Col>
            <Col className="col-6 align-right">
              <h5 className={`${s.light} text-right`} onClick={closeMobileFilter}>
                <Translate id="close" />
              </h5>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col className="col-4">
              <h5 className={s.light} onClick={resetFilters}>
                <Translate id="reset" />
              </h5>
            </Col>
            <Col className="col-4">
              <h6 className="text-center mb-0"><Translate id="filter" /></h6>
            </Col>
            <Col className="col-4 align-right">
              <h5 className={`${s.light} text-right`} onClick={closeMobileFilter}>
                <Translate id="close" />
              </h5>
            </Col>
          </Row>
        )}
        <div className={s.alternatives}>
          <Search fullWidth value={props.activeFilters.search} />
          {props.useFilters && activeCat ?
            activeCat.options.map(sub =>
              <MobileFilterOption
                key={sub.id}
                label={sub.label}
                click={() => toggleOption(activeCat.id, sub)}
                icon={isSelected(activeCat.id, sub.id) ? <CheckOn className={s.checkIcon} /> : <CheckOff className={s.checkIcon} />}
              />
          ) : props.useFilters && props.filters.map(cat =>
            <MobileFilterOption
              key={cat.id}
              label={cat.placeholder}
              icon={showSelected(cat)}
              click={() => setActiveCat(cat)}
            />
          )}
        </div>
        <div className={s.buttonWrapper}>
          <Button label={<Translate id="applyFilter" />} handleClick={saveOptions} fullWidth />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MobileFilter;
