import React, { useCallback, useContext, useMemo, useState } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import { hall } from "helpers";
import FavoriteButton from "components/FavoriteButton";
import s from "./EventItem.module.css";
import { store } from "../../store";
import countries from "i18n-iso-countries";
import MeetingRequest from '../MeetingRequest';

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/no.json"));

function replaceWithBr(txt) {
  return (txt || '').replace(/\n/g, "<br />")
}


const createCategoriesList = (
  categories,
  onCategoryClick,
  onClearFilter,
  filters,
  lang
) => {
  return categories && Array.isArray(categories) ? categories.map((category) => {
    const isActive =
      filters.find((filter) => filter.id === category.id) !== undefined;
    const activeClass = isActive ? s.active : "";
    return (
      <Col
        className="col-auto pr-2 mb-2"
        key={`category-${category.id}`}
        onClick={(evt) => {
          evt.stopPropagation();
          if (isActive) {
            onClearFilter("categories");
          } else {
            onCategoryClick(category);
          }
        }}
      >
        <span className={`${s.category} ${activeClass}`}>
          {lang === "en" ? category.en.name : category.name}
        </span>
      </Col>
    );
  }) : [];
};

const createBrandList = (brands, onBrandClick, onClearFilter, filters) => {
  return brands && Array.isArray(brands) ? brands.map((brand) => {
    const isActive =
      filters.find((filter) => filter.id === brand.id) !== undefined;
    const activeClass = isActive ? s.active : "";
    return (
      <Col
        className="col-auto pr-2 mb-2"
        key={`brand-${brand.id}`}
        onClick={(evt) => {
          evt.stopPropagation();
          if (isActive) {
            onClearFilter("brands");
          } else {
            onBrandClick(brand);
          }
        }}
      >
        <span className={`${s.brand} ${activeClass}`}>{brand.name}</span>
      </Col>
    );
  }) : [];
};

const createContactList = (contacts) => {
  if (!contacts) {
    return null;
  }

  return contacts && Array.isArray(contacts) ? contacts.map((contact, index) => {
    return (
      <div key={index} className="mb-3">
        <div>
          {contact.firstname} {contact.lastname}
        </div>
        <div>{contact.email}</div>
      </div>
    );
  }) : [];
};

const EventItem = (props) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { lang } = state;
  const categoriesFilter = props?.filter?.categories ?? [];
  const brandsFilter = props?.filter?.brands ?? [];
  const contacts = props.data.primary_contacts ?? [];
  const onClearFilter = (listType) => {
    dispatch({ type: "FILTER", payload: { [listType]: [] } });
  };
  const categoryList = useMemo(
    () =>
      createCategoriesList(
        props.data.product_categories,
        props.changeCat,
        onClearFilter,
        categoriesFilter,
        lang
      ),
    [props.data.product_categories, categoriesFilter]
  );
  const brandsList = useMemo(
    () =>
      createBrandList(
        props.data.brands,
        props.changeBrand,
        onClearFilter,
        brandsFilter
      ),
    [props.data.brands, brandsFilter]
  );
  const contactList = useMemo(() => createContactList(contacts), [contacts]);
  const [isExpanded, setIsExpanded] = useState(false);
  const conditionalFavorite = !props.narrowView
    ? "col-md-auto justify-content-end order-md-last"
    : null;
  const {standnumber, is_draft, url} = props.data;
  const getHallNr = hall(standnumber);
  const description =
    lang === "no" ? props?.data?.description : props?.data?.en?.description;

  const onExpandClick = () => {
    setIsExpanded(!isExpanded);
  };


  
  const openModal = () => {
    setIsExpanded(true);
  };

  const renderCompanyName = () => {
      if (
        props.data.show_exhibitor_name &&
        (props.data.standname && props.data.company_name && 
        (props.data.standname.toLowerCase()!== props.data.company_name.toLowerCase()))
      ) {
      return <p className={s.companyName}>{props.data.company_name}</p>;
    } else {
      return;
    }
  }

  const renderCoexhibitorParentName = () => {
    if (props.data.parent_stand_name && props.data.parent_stand_name.toLowerCase() !== '') {
      return <p className={s.companyName}>{props.data.parent_stand_name}</p>;
    } else {
      return;
    }
  }

  const renderCountry = () => {
    if (props.data.countryCode !== null) {
      if (lang === "no") {
        const country = countries.getName(props.data.countryCode, "no", {select: "official"});
        return <p className={s.companyName}>{country}</p>;
      } else {
        const country = countries.getName(props.data.countryCode, "en", {select: "official"});
        return <p className={s.companyName}>{country}</p>;
      }
    } else {
      return;
    }
  }

  const getDomainUrl = useCallback((vl)=> {
    return vl && (vl.indexOf('https://')<0) &&  (vl.indexOf('http://')<0)? `http://${vl.replace("www.","")}`: vl
  });

  
  return standnumber && !is_draft ? (
    <div
      onClick={openModal}
      className={`${s.event} ${!props.data.url && s.noUrl}`}
    >
      <Row>
        <Col className={`col-12 mb-2 ${conditionalFavorite}`}>
          <FavoriteButton id={props.data.id} exhibitor />
        </Col>
      </Row>

        <div className="container-fluid">
          <Row>
            <div className={s.infoContentCol}>
              <h2 className={s.hallInfo}>
                {getHallNr}
                <span className={s.bullet}>&bull;</span>
                <span className={s.standNr}>{standnumber}</span>
              </h2>
              <h4 className={s.title}>{props.data.standname}</h4>
              {renderCompanyName()}
              {renderCoexhibitorParentName()}
              {renderCountry()}
            </div>
            <div className={s.infoContentAside}>
              <MeetingRequest 
                itemData = {props.data || {}}
              />
            </div>
          </Row>
        </div>
        
      



      <Row className="mt-4 no-gutters">
        {categoryList && categoryList}

        {brandsList && brandsList}
      </Row>

      {isExpanded && (
        <Modal isOpen={isExpanded} toggle={onExpandClick} centered={true}>
          <ModalBody>
            <div>
              <button className="close" onClick={onExpandClick}>
                &times;
              </button>
              <div className={s.content}>
                <h2 className={s.hallInfo}>
                  {getHallNr}
                  <span className={s.bullet}>&bull;</span>
                  <span className={s.standNr}>{standnumber}</span>
                </h2>
                <h4 className={s.title}>{props.data.standname}</h4>
              </div>
              {description && (
                <div className={s.content}>
                  <div
                    className={s.description}
                    dangerouslySetInnerHTML={{ __html: replaceWithBr(description)}}
                  />
                </div>
              )}
              <div className={s.content}>
                <div>
                  {url?
                    <a href={getDomainUrl(url)} target="_blank">{url}</a>
                  : null}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
    </div>
  ) : (
    <></>
  );
};

export default EventItem;
