import React, { useContext, useState } from "react";
import { store } from "store.js";

function Search(props) {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { lang } = state;
  const [search, changeSearch] = useState(props.value || "");

  const handleSearch = (value) => {
    changeSearch(value);
    dispatch({ type: "FILTER", payload: { search: value } });
  };

  return (
    <input
      className={props.fullWidth && "mobile-search mb-3"}
      type="text"
      value={search}
      placeholder={lang === "no" ? "Søk" : "Search"}
      onChange={(e) => handleSearch(e.target.value)}
    />
  );
}

export default Search;
