// store.js
import React, {createContext, useReducer} from 'react';

const initialState = { favorites: [], filter: { favorites: false}, showFilter: {} };
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'SET_CONFIG':
        return {
          ...state,
          config: action.payload,
        };
      case 'UPDATE_LANG':
        return {
            ...state,
          lang: action.payload,
        };
      case 'SET_FILTER':
        return {
          ...state,
          showFilter: {
            ...action.payload,
          }
        };
      case 'GET_FAVORITES':
        return {
          ...state,
          favorites: action.payload,
        };
      case 'GET_USER':
        return {
          ...state,
          user: action.payload,
        };
      case 'GET_STREAMS':
        return {
          ...state,
          streams: action.payload,
        };
      case 'FETCH_EVENTS':
        return {
          ...state,
          events: action.payload,
        };
       case 'FILTER':
         return {
           ...state,
           filter: {
             ...state.filter,
             ...action.payload,
           },
        };
      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }
